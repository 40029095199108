<template>
  <div class="nomore-box">
    <van-divider>{{text}}</van-divider>
  </div>
</template>
<script>
export default {
  name: 'nomore',
  props: {
    text: {
      type: String,
      default: "没有更多了"
    }
  }
}
</script>
<style lang="scss" scoped>
</style>